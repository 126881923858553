:root {
  --yellow: #ffc000;
  --white: #ffffff;
  --black: #000000;
  --brightOrange: #ffe0a0;
  --outlineYellow: #ffe927;
  --textWhite: #fff0cf;
}
* {
  box-sizing: border-box;
}
body {
  background-color: var(--yellow);
  background-image: url(./images/bg.svg);
  background-size: 100% auto;
  background-repeat: repeat-y;
  min-height: 100vh;
}
.text-white {
  color: var(--white);
}
.text-offwhite {
  color: var(--textWhite);
}
.text-black {
  color: var(--black);
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.w800 {
  font-weight: 800;
}
.w900 {
  font-weight: 900;
}
.header-logo {
  height: 40px;
}
.footer-logo > img {
  height: 60px;
}
.footer-logo {
  margin: 0 0 80px 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--black);
}
footer,
.forSME-div {
  background-image: url(./images/bg.svg);
  background-size: 100vw auto;
  background-position: center center;
  padding: 60px;
  margin: 50px 0 50px 0;
}
.uk-navbar-nav > li > a,
.uk-navbar-item p {
  text-transform: unset;
  color: var(--black);
  font-weight: 700;
}
.uk-navbar-nav > li > a:hover {
  color: var(--black);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  transition: text-decoration 0.2s ease-in-out;
}
.uk-navbar-item p > img {
  width: 2em;
  height: 2em;
  margin-left: 1em;
}
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  font-size: 1rem;
}
.social-icons {
  margin: 0 0 15px 0;
}
.social-icons > a {
  margin: 10px 5px;
}
.social-icons > a > img {
  width: 1.5rem;
  height: 1.5rem;
}

/* OUTLINE DIV ================== */
.outlineDiv {
  border: 3px solid var(--outlineYellow);
  border-radius: 20px;
  position: relative;
}
.outlineDiv::before {
  position: absolute;
  content: " ";
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  top: -6px;
  left: -6px;
  right: 0;
  bottom: 0;
  border: 3px solid var(--black);
  border-radius: 23px;
  z-index: 0;
}
.outlineDiv > div {
  position: relative;
  z-index: 1;
}

/* HERO TOP ===================== */
.hero-top-div {
  width: 100%;
  min-height: calc(95vh - 100px);
  display: flex;
}
.hero-top-div > div {
  min-height: 100%;
}
.hero-heading {
  font-size: 3rem;
}
.hero-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}
.hero-getstarted {
  font-size: 1.2rem;
}
.hero-getstarted img {
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: middle;
  margin-left: 1rem;
}

/* LOCATIONS COMPONENT ===================== */
.location-name,
.krona {
  font-family: "Krona One", sans-serif;
}
.location-cursor {
  width: 40px;
  height: 40px;
  display: block;
}
.location-slider-previous,
.location-slider-next {
  width: 35px;
}
.location-slider-previous {
  transform: rotateY(180deg);
}
.location-slider-previous-a {
  left: -15px;
}
.location-slider-next-a {
  right: -15px;
}

/* HOW IT WORKS ================ */
.how-it-works-div {
  width: 45px;
  height: 45px;
  position: relative;
}
.how-it-works-no {
  width: 100%;
  height: 100%;
  background: var(--black);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.how-it-works-div::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--brightOrange);
  z-index: 1;
  bottom: -7px;
  left: -7px;
}
.how-it-works-no > h3 {
  color: var(--white);
  margin: 0;
  font-weight: 600;
  line-height: 1;
}
.how-it-works-bike {
  height: 600px;
  max-width: unset;
  margin-left: -300px;
}

/* TRUST US COMPONENT ======================================================================= */
.trust-img {
  height: 100px;
  margin-bottom: 50px;
}

/* FOR SME SECTIONS ================================================================= */
.forSME-div {
  padding: 100px 0;
  overflow: hidden;
}
.forSME-hero {
  font-size: 4rem;
}
.forSME-div .hero-getstarted:hover {
  color: var(--textWhite);
}
.forSME-stall {
  height: 30rem;
  max-width: unset;
}

/* FAQ =========================================== */
.faq-head {
  padding: 20px 30px;
  cursor: pointer;
}
.faq-img {
  height: 30px;
}
.faq-img.open {
  transform: rotateX(180deg);
}
.faq-body-wrapper {
  max-height: 0;
  transition: max-height 0.4s ease-in;
  overflow: hidden;
}
.faq-body-wrapper.open {
  max-height: 1000px;
}
.faq-body {
  padding: 20px 30px;
}

@media (max-width: 960px) {
  .padding-sm {
    padding: 30px 30px 0 30px;
  }
  .text-centerM {
    text-align: center;
  }
  .hero-heading {
    font-size: 2.5rem;
  }
  .how-it-works-bike {
    height: 400px;
    margin-left: -100px;
  }
  .forSME-hero {
    font-size: 3rem;
  }
  .forSME-stall {
    height: 20rem;
    margin-left: 150px;
  }
  footer {
    padding: 30px;
  }
  .footer-logo > img {
    width: 200px;
    height: auto;
    margin-top: 60px;
  }
  .uk-nav-primary > li > a {
    color: var(--black);
    font-weight: 600;
  }
  .mobileMenu {
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    overflow: hidden;
  }
  .mobileMenu.open {
    max-height: 1000px;
  }
}
